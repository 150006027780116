<template>
  <div class="imgDiv">
    <el-image style="width: 48px; height: 48px; margin: 3px 3px 3px 3px" v-for="(item,index) in imageList" :key="index" :src="item[urlField]" :preview-src-list="getImgList(index)"></el-image>
  </div>
</template>

<script>
export default {
  name: "ImageViewerMiniComponent",
  props: {
    urlField: {type: String, default: 'url'},
    imageList: {
      type: Array, default: function () {
        return []
      }
    }
  },
  data() {
    return {};
  },
  created() {

  },
  methods: {
    getImgList(index) {
      let arr = []
      let i = 0;
      for (i; i < this.imageList.length; i++) {
        arr.push(this.imageList[i + index][this.urlField])
        if (i + index >= this.imageList.length - 1) {
          index = 0 - (i + 1);
        }
      }
      return arr;
    },
  }
}
</script>

<style scoped>

.imgDiv {
  border: solid 1px #e5e5e5;
  padding: 2px;
  height: 57px;
}

</style>