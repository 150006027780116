<template>
  <div>
    <el-row :gutter="20" style="margin-bottom: 20px;">
      <el-col :span="20">
        <el-select v-model="queryInfo.helpType" placeholder="协助类型" class="input-short" filterable clearable>
          <el-option v-for="item in helpTypeList" :key="item.value" :label="item.name" :value="item.value"></el-option>
        </el-select>
        <el-input clearable maxlength="64" v-model="queryInfo.orderNo" placeholder="单号" class="input-short"></el-input>
        <el-input clearable maxlength="64" v-model="queryInfo.processId" placeholder="协助单ID" class="input-short"></el-input>
        <el-switch v-model="queryInfo.finish" active-color="#13ce66" inactive-color="#cccccc"/>
        <span>查看已完成</span>
        <el-button type="primary" style="margin-left: 10px" @click="currentChange(1)" :loading="loading">查询</el-button>
      </el-col>
    </el-row>

    <ProcessTableComponent
        :processList="processList"
        :pageSize="pageSize"
        :pageNum="queryInfo.pageNo"
        :total="total"
        @pageChange="currentChange"
    ></ProcessTableComponent>

  </div>
</template>

<script>
import ProcessTableComponent from "@/views/process/ProcessTableComponent";

export default {
  name: "MyCreatedHelp",
  components: {ProcessTableComponent},
  data() {
    return {
      queryInfo: {
        helpType: '',
        orderNo: '',
        processId: '',
        finish: false,
        pageNo: 1
      },
      processList: [],
      loading: false,
      pageSize: 0,
      total: 0
    }
  },
  created() {
    this.search()
  },
  computed: {
    helpTypeList() {
      return this.$store.getters.getDictList('helpType') || [];
    }
  },
  methods: {
    currentChange(pageNo) {
      this.queryInfo.pageNo = pageNo;
      this.search()
    },
    search() {
      this.$axios.post('help/queryCreatedHelp', this.queryInfo).then(response => {
        let res = response.data
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }
        this.processList = res.data.records;
        this.total = res.data.total;
        this.pageSize = res.data.size;
      }, error => {
        this.loading = false;
        return this.$message.error(`操作失败，${error.message}`);
      })
    },
  }
}
</script>

<style scoped>
.input-short {
  width: 250px;
  margin-right: 5px;
}
</style>