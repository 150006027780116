<template>
  <div style="display: flex;flex-direction: column">
    <div v-for="(file,index) in list" :key="index">
      <el-button type="text" @click="download(file)">{{ getFileName(file, index) }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "FileDownloadComponent",
  props: {
    url: String,
    name: String,
    fileList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    list() {
      if (this.fileList.length > 0) {
        return this.fileList;
      } else {
        let arr = [];
        arr.push({
          url: this.url,
          name: this.name
        })
        return arr;
      }
    }
  },
  methods: {
    getFileName(file, index) {
      return file.originalName || file.name || `未命名文件${index + 1}`
    },
    download(file) {
      const a = document.createElement('a')
      fetch(file.url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
        a.href = URL.createObjectURL(blob)
        a.download = file.name || '' // 下载文件的名字
        // a.download = url.split('/')[url.split('/').length -1] //  // 下载文件的名字
        document.body.appendChild(a)
        a.click()

        //在资源下载完成后 清除 占用的缓存资源
        window.URL.revokeObjectURL(a.href);
        document.body.removeChild(a);
      })
    }
  },
}
</script>

<style scoped>

</style>