<template>
  <div class="title">{{ title }}</div>
</template>

<script>
export default {
  name: "TableTitleComponent",
  props: {title: String}
}
</script>

<style scoped>
.title {
  padding: 5px 0;
  font-weight: bold;
}
</style>