<template>
  <div>
    <TableTitleComponent title="任务一览"/>
    <el-table
        :data="processList"
        border
        :height="340"
        style="width: 100%" stripe
        @row-click="rowClick"
        @current-change="handleCurrentChange">
      <el-table-column align="center" width="50">
        <template slot-scope="scope">
          <el-radio v-model="radio" :label="scope.row.id" style="margin-right: -15px">&nbsp;</el-radio>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="orderNo" label="单号" min-width="220"></el-table-column>
      <el-table-column align="center" prop="helpTypeName" label="协助类型" min-width="220"></el-table-column>
      <el-table-column align="center" prop="id" label="协助单ID" width="160"></el-table-column>
      <el-table-column align="center" prop="createUserName" label="总任务发起人" width="120"></el-table-column>
      <el-table-column align="center" prop="createTime" label="总任务发起时间" width="150"></el-table-column>
      <el-table-column align="center" prop="taskNodeName" label="当前任务" width="120"></el-table-column>
      <el-table-column align="center" prop="taskUserName" label="当前责任人" width="120"></el-table-column>
      <el-table-column align="center" prop="taskCreateTime" label="当前任务发起时间" width="150"></el-table-column>
    </el-table>
    <div class="page-box">
      <el-pagination background layout="total,prev, pager, next" :page-size="pageSize" :total="total" @current-change="currentChange" :current-page.sync="currentPage"></el-pagination>
    </div>

    <template v-if="showLog">
      <TableTitleComponent :title="logTitle"/>
      <el-table :data="logList" :height="340" border style="width: 100%;" class="logTable">
        <el-table-column prop="createTime" label="时间" width="150"></el-table-column>
        <el-table-column prop="eventLabel" label="执行事件" width="100"/>
        <el-table-column prop="createUserName" label="操作人" width="100"/>
        <el-table-column prop="nodeName" label="任务节点" width="120"/>
        <el-table-column label="图片" width="200" align="left">
          <template slot-scope="scope">
            <ImageViewerMiniComponent v-if="scope.row.imageList.length > 0" :image-list="scope.row.imageList"/>
          </template>
        </el-table-column>
        <el-table-column label="Excel文件" width="80" align="left">
          <template slot-scope="scope">
            <FileDownloadComponent v-if="scope.row.fileUrl" name="下载文件" :url="scope.row.fileUrl"></FileDownloadComponent>
          </template>
        </el-table-column>
        <el-table-column prop="eventText" label="输入信息"></el-table-column>
      </el-table>
    </template>


  </div>
</template>

<script>
import TableTitleComponent from "@/components/TableTitleComponent";
import ImageViewerMiniComponent from "@/components/ImageViewerMiniComponent";
import FileDownloadComponent from "@/components/FileDownloadComponent";

export default {
  name: "ProcessTableComponent",
  components: {FileDownloadComponent, ImageViewerMiniComponent, TableTitleComponent},
  props: {
    showLog: {type: Boolean, default: true},
    total: {type: Number, default: 0},
    pageSize: {type: Number, default: 10},
    processList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      radio: '',
      currentRow: {},
      currentPage: 1,
      orderNo: '',
      logList: []
    }
  },
  computed: {
    logTitle() {
      let title = '任务执行状况';
      if (this.orderNo) {
        title += `(单号：${this.orderNo})`
      }
      return title
    }
  },
  methods: {
    currentChange(currentPage) {
      this.currentPage = currentPage;
      this.$emit('pageChange', this.currentPage)
    },
    handleCurrentChange(row) {
      if (row == null) {
        this.currentRow = {};
        this.logList = [];
        this.orderNo = '';
        return;
      }

      this.currentRow = row;
      this.logList = row.logList;
      this.orderNo = row.orderNo;
    },
    rowClick(row) {
      this.currentRow = row;
      this.radio = row.id
    }
  },
  watch: {
    currentRow() {
      console.log('currentRow change');
      this.$emit('selectChange', this.currentRow)
    },
    pageNum(val) {
      this.currentPage = val
    }
  }
}
</script>

<style scoped>

</style>